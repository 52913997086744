import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, ViewChild, Input,NgModule   } from '@angular/core';
import { VideoService } from '../services/video.service';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';

import { Capacitor, Plugins } from '@capacitor/core';
const { CapacitorVideoPlayer } = Plugins;

@Component({
  selector: 'app-camera-video',
  templateUrl: './camera-video.component.html',
  styleUrls: ['./camera-video.component.scss'],
})

export class CameraVideoComponent implements AfterViewInit{
  mediaRecorder: MediaRecorder;
  videoPlayer: any;
  isRecording = false;
  videos = [];
  display_name = "";
  @Input() page: any;
  @ViewChild('video') captureElement: ElementRef;

  constructor(private videoSevice: VideoService, private changeDetector: ChangeDetectorRef, private router: Router) {

  }

  ngOnInit() {
    let user = JSON.parse(localStorage.getItem('user'));
    if(user){
      this.display_name = user.first_name + " " + user.last_name;
    }else{
      this.router.navigate(['/'])
          localStorage.clear();
    }
  }

  async ngAfterViewInit(){
    this.videos = await this.videoSevice.loadVideos();

    if(Capacitor.isNative){
      this.videoPlayer = CapacitorVideoPlayer;
    } else{
      // this.videoPlayer = WebVPPlugin.CapacitorVideoPlayer
    }
    this.recordVideo();
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.stopRecord();
      }
    })
  }

  async recordVideo(){

    let mimeType='video/webm;codecs=h265';

    const types = [
      "video/webm;codecs=h265",
      "video/webm;codecs=h264",
      "video/webm;codecs=vp8",
      "video/webm;codecs=vp9",
      "video/webm;codecs=daala",
      "video/mp4",
      "video/webm",
      "video/mpeg",
    ];


    for (const type of types) {

      if(MediaRecorder.isTypeSupported(type)){
        console.log('Supported '+type)
        mimeType=type;
        break;
      }else{
        console.log('NO Supported '+type)
      }

    }

    const stream = await navigator.mediaDevices.getUserMedia({
      video: {
        facingMode: 'user'
      },
      audio: false
    });

    this.captureElement.nativeElement.srcObject = stream;
    this.isRecording = true;

    const options = {mimeType: mimeType};
    this.mediaRecorder = new MediaRecorder(stream, options);

    let chunks = [];

    this.mediaRecorder.ondataavailable = (event) => {
      if( event.data && event.data.size > 0){
        chunks.push(event.data)
      }
    }

    this.mediaRecorder.onstop = async (event) => {

      let mimeType='video/webm;codecs=h265';

      const types = [
        "video/webm;codecs=h265",
        "video/webm;codecs=h264",
        "video/webm;codecs=vp8",
        "video/webm;codecs=vp9",
        "video/webm;codecs=daala",
        "video/mp4",
        "video/webm",
        "video/mpeg",
        "video/mp4",
      ];


      for (const type of types) {

        if(MediaRecorder.isTypeSupported(type)){
          console.log('Supported '+type)
          mimeType=type;
          break;
        }else{
          console.log('NO Supported '+type)
        }

      }

      const videoBuffer = new Blob(chunks, {type: mimeType});
      // Store the video
      await this.videoSevice.storeVideo(videoBuffer);

      // reload the list
      this.videos = this.videoSevice.videos;
      this.changeDetector.detectChanges;
    }
    this.mediaRecorder.start();
  }

  stopRecord() {
    if (this.mediaRecorder && this.mediaRecorder.state !== 'inactive') {
      this.mediaRecorder.stop();
    }
    this.mediaRecorder = null;
    if (this.captureElement && this.captureElement.nativeElement) {
      this.captureElement.nativeElement.srcObject = null;
    }
    this.isRecording = false;
  }
  

  async play(video){
    const base64Data = await this.videoSevice.getVideoUrl(video);

    await this.videoPlayer.initPlayer({
      mode: 'fullscreen',
      url: base64Data,
      playerId: 'fullscreen',
      componentTag: 'app-enmarcar'
    })
  }
}
